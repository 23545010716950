<template>
  <div>
    <div>
      <prices-upload
        style="margin-top: 25px"
        @update="$emit('update')"
        catalog-type="client"
        :clients="clients"
      />
    </div>
    <prices-table catalog-type="client" :loading="loading" />
  </div>
</template>
<script>
import PricesTable from "@/components/prices/PricesTable"
import PricesUpload from "@/components/prices/PricesUpload"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "ClientPrices",
  components: {
    PricesTable,
    PricesUpload,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    clients() {
      const ret = []
      for (const client of this.clientsAll) {
        ret.push({
          value: client.id,
          label: client.name,
        })
      }
      return ret
    },
    ...mapGetters({
      clientsAll: "clients/clients",
    }),
  },
  async mounted() {
    await this.getPrices()
    await this.fetchClients()
  },
  methods: {
    async getPrices() {
      this.loading = true
      await this.$store.dispatch("prices/fetchPrices", "client")
      this.loading = false
    },
    ...mapActions({
      fetchClients: "clients/fetchClients",
    }),
  },
}
</script>
